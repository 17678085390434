import { Product } from '@lambda/commons/apis/shopify/types/storefront';
import {
  ReebeloConditions,
  ReebeloLanguage,
  TagHelpers,
  REEBELO_STORE_TO_LANGUAGE,
} from '@lambda/reebelo';
import { convertCellularFieldsToServiceProvider } from '@lambda/business/offers/cellular';
import { extractTags } from '@lambda/reebelo/src/tagHelpers';
import { get as _get, groupBy, pick, pickBy, sortBy, uniqBy } from 'lodash-es';
import {
  Connectivities,
  PimProductT,
  SimSlot,
} from '@lambda/apis/src/dynamoDb/types';
import { SearchOffers } from '@lambda/commons/apis/elasticsearch/types';
import settings from '@/settings';
import { CollectionData, CollectionKey, LeftMenu } from '@/settings/types';
import { CollectionSummaries } from '@/api/collections/summary';
import { ConfigT } from '@/settings/configs/validate';
import { CollectionSummaryData } from '@/components/homepage/CollectionsSlider';
import warrantyPricing from '@/settings/reebelocare';

export const isNaStore =
  settings.store === 'reebelo-us' || settings.store === 'reebelo-ca';
export const SimSlotSkus: Record<ReebeloLanguage, Record<SimSlot, string>> = {
  en: {
    DS: 'Dual Sim',
    SS: 'Single Sim',
  },
  ko: {
    DS: '듀얼 SIM',
    SS: '단일 SIM',
  },
  zh: {
    DS: '雙卡',
    SS: '單卡',
  },
};

export const Conditions: Record<
  ReebeloLanguage,
  Record<ReebeloConditions, string>
> = {
  en: {
    'Brand New': 'Brand New',
    Premium: 'Premium',
    'As New': 'As New',
    Pristine: 'Pristine',
    Excellent: 'Excellent',
    'Very Good': 'Very Good',
    Good: 'Good',
    Acceptable: 'Acceptable',
  },
  ko: {
    'Brand New': '새상품',
    Premium: '프리미엄',
    'As New': 'S급',
    Pristine: 'S급',
    Excellent: 'A급',
    'Very Good': 'B급',
    Good: 'B급',
    Acceptable: 'C급',
  },
  zh: {
    'Brand New': '全新品',
    Premium: '優質',
    'As New': '5星品',
    Pristine: '5星品',
    Excellent: '4.5星品',
    'Very Good': '4星品',
    Good: '4星品',
    Acceptable: '3.5星品',
  },
};

export const ConnectivitieSkus: Record<
  ReebeloLanguage,
  Record<Connectivities, string>
> = {
  en: {
    C: 'Cellular',
    W: 'WiFi',
    CW: 'Cellular + WiFi',
    '4G': '4G',
    '5G': '5G',
    G: 'GPS',
    CG: 'Cellular + GPS',
    B: 'Bluetooth',
    BL: 'Bluetooth + LTE',
  },
  ko: {
    C: '모바일 네트워크',
    W: 'WiFi',
    CW: '모바일 네트워크 + Wifi',
    '4G': '4G',
    '5G': '5G',
    G: 'GPS',
    CG: 'Cellular + GPS',
    B: 'Bluetooth',
    BL: 'Bluetooth + LTE',
  },
  zh: {
    C: '行動網路',
    W: 'WiFi',
    CW: '行動網路 + Wifi',
    '4G': '4G',
    '5G': '5G',
    G: 'GPS',
    CG: 'Cellular + GPS',
    B: 'Bluetooth',
    BL: 'Bluetooth + LTE',
  },
};

export type VariantNames =
  | 'serviceProvider'
  | 'mvno'
  | 'screenSize'
  | 'color'
  | 'storage'
  | 'condition'
  | 'cpu'
  | 'ram'
  | 'simSlot'
  | 'connectivity'
  | 'watchFaceSize'
  | 'watchCaseMaterial'
  | 'watchCaseColor'
  | 'watchBandType'
  | 'watchBandColor'
  | 'watchBandMaterial';

export const VARIANTS: VariantNames[] = [
  'serviceProvider',
  'screenSize',
  'connectivity',
  'storage',
  'color',
  'cpu',
  'ram',
  'simSlot',
  'condition',
];
export const SMARTWATCHVARIANTS: VariantNames[] = [
  'watchCaseMaterial',
  'watchFaceSize',
  'watchCaseColor',
  'connectivity',
  'watchBandMaterial',
  'watchBandType',
  'watchBandColor',
  'condition',
  'storage',
];

export type VariantObj = Record<VariantNames, string>;
export type SelectionT = Omit<Partial<VariantObj>, 'serviceProviders'> & {
  serviceProvider?: string;
};

export const tagToSelection = (tags: string[]): SelectionT => {
  const extracted = TagHelpers.extractTags(tags);
  const serviceProvider = convertCellularFieldsToServiceProvider(extracted);
  const variants =
    extracted.category === 'Smartwatches' ? SMARTWATCHVARIANTS : VARIANTS;
  const allObject = pick(
    { ...extracted, serviceProvider: serviceProvider?.[0] },
    variants,
  ) as VariantObj;

  return pickBy(allObject, (p) => p); // remove undefined values
};

export const getPrice = (offer: Product | undefined) =>
  Number(offer?.variants.edges?.[0].node.priceV2.amount);

export type ReplacedProduct = SearchOffers & {
  /** id of the more expensive offer that was replaced in the UI. if `null` then the offer was added for UI purpose. */
  replacedFromCondition?: ReebeloConditions;
  serviceProvider?: string;
  mvno?: string;
};

type ColorType = 'colors' | 'watchBandColors' | 'watchCaseColors';

const getLocaleColors = (
  product: PimProductT,
  language: ReebeloLanguage,
  colorType: ColorType,
): string[] =>
  Object.values(
    product.locale?.[language]?.[colorType] || product[colorType] || {},
  );

/**
 * Remove offers that don't have corresponding tags with the product.
 * and also offer that contains invalid service provider in US
 */
export const removeUnfitOffers = (
  offers: SearchOffers[],
  product: PimProductT,
) => {
  const language = REEBELO_STORE_TO_LANGUAGE[settings.store];

  const colors = getLocaleColors(product, language, 'colors');
  const simSlots = Object.values(product.simSlot || {}).map(
    (s) => SimSlotSkus[language][s],
  );
  const connectivities = product.connectivities.map(
    (c) => ConnectivitieSkus[language][c],
  );

  return offers
    .filter((o) => {
      const tags = extractTags(o.tags);
      let isValid = true;

      const compare = (key: keyof typeof tags, list?: string[]) => {
        if (
          list == null ||
          list.length === 0 ||
          list.includes(tags[key] as string)
        )
          return;
        isValid = false;
        console.warn(
          `${o.productId}-${key}: ${tags[key]} does not exists in ${list.join(
            ',',
          )}`,
        );
      };

      if (o.categories && o.categories[0] === 'Smartwatches') {
        const bandColors = getLocaleColors(
          product,
          language,
          'watchBandColors',
        );
        const caseColors = getLocaleColors(
          product,
          language,
          'watchCaseColors',
        );

        compare('watchBandColor', bandColors);
        compare('watchBandType', product.watchBandTypes);
        compare('watchBandMaterial', product.watchBandMaterials);
        compare('watchCaseColor', caseColors);
        compare('watchCaseMaterial', product.watchCaseMaterials);
      } else {
        compare('simSlot', simSlots);
        compare('ram', product.rams);
        compare('cpu', product.cpus);
        compare('color', colors);
      }

      compare('storage', product.storages);
      compare('connectivity', connectivities);

      return isValid;
    })
    .filter((o) => {
      // skip this filter if not US store or there is no service providers
      if (
        settings.store !== 'reebelo-us' ||
        o.serviceProviders == null ||
        o.serviceProviders.length === 0
      )
        return true;

      return (o.serviceProviders ?? []).some((provider) =>
        ['Unlocked', 'AT&T', 'T-Mobile', 'Verizon'].includes(provider),
      );
    });
};

export const generateAltTagForOffer = (
  offer: SearchOffers,
  productName: string,
): string => {
  if (!offer) return '';
  let altTag = productName;

  if (offer.categories?.includes('Laptops')) {
    if (offer.cpu) altTag += ` ${offer.cpu}`;
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Smartphones')) {
    if (offer.storage) altTag += ` ${offer.storage}`;
    if (offer.serviceProviders?.includes('Unlocked')) altTag += ` Unlocked`;
    else if (offer?.serviceProviders?.length)
      altTag += ` for ${offer.serviceProviders[0]}`;
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Tablets')) {
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Monitors')) {
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Gaming Consoles')) {
    if (offer.storage) altTag += ` ${offer.storage}`;
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Earphones')) {
    if (offer.color) altTag += ` in ${offer.color}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  if (offer.categories?.includes('Smartwatches')) {
    if (offer.watchCaseMaterial) altTag += ` ${offer.watchCaseMaterial}`;
    if (offer.watchFaceSize) altTag += ` ${offer.watchFaceSize}`;
    if (offer.watchCaseColor) altTag += ` in ${offer.watchCaseColor}`;
    if (offer.condition) altTag += ` in ${offer.condition} condition`;

    return altTag;
  }

  return altTag;
};

export const normalizeOffers = (
  offers: Array<SearchOffers>,
  collectionItem: CollectionData,
): Array<ReplacedProduct> => {
  const result: Array<ReplacedProduct> = [];

  offers.forEach((offer) => {
    if (offer.serviceProviders && offer.serviceProviders.length > 0) {
      const { serviceProviders, ...offerWithoutServiceProviders } = offer;

      serviceProviders.forEach((serviceProvider) => {
        result.push({
          ...offerWithoutServiceProviders,
          serviceProvider,
          defaultImageAltTag: generateAltTagForOffer(
            offer,
            `${collectionItem.title}`,
          ),
        });
      });
    } else {
      result.push({
        ...offer,
        defaultImageAltTag: generateAltTagForOffer(
          offer,
          `${collectionItem.title}`,
        ),
      });
    }
  });

  return result;
};

/** Remove expensive offers, this comes from the fact that `product_is_cheapest` might tag several products with the same condition */
export const keepOnlyCheapestOffers = (
  offers: ReplacedProduct[],
): ReplacedProduct[] => {
  // offers are expected to be sorted by cheapest first
  const offersWithKey = offers.map((offer) => {
    let variants: VariantNames[] = VARIANTS;

    if (offer && offer.categories && offer.categories[0] === 'Smartwatches')
      variants = SMARTWATCHVARIANTS;

    const key = variants.map((v) => offer[v]).join('#');

    return { key, offer, price: offer.price };
  });

  return uniqBy(sortBy(offersWithKey, 'price'), 'key').map((o) => o.offer);
};

/** Replace an offer if it exists is a better condition and at a cheaper price */
export const replaceExpensiveOffers = (
  offers: ReplacedProduct[],
): ReplacedProduct[] => {
  const language = REEBELO_STORE_TO_LANGUAGE[settings.store];
  const RC = Conditions[language] as Record<
    ReebeloConditions,
    ReebeloConditions
  >;

  const groups = Object.values(
    groupBy(offers, (offer) => {
      let variants: VariantNames[] = VARIANTS;

      if (offer && offer.categories && offer.categories[0] === 'Smartwatches')
        variants = SMARTWATCHVARIANTS;

      return variants
        .filter((v) => v !== 'condition')
        .map((v) => offer[v])
        .join('#');
    }),
  );

  return groups.flatMap((group) => {
    const getWithCond = (cond: ReebeloConditions) =>
      group.find((p) => p.tags.includes(`Condition_${cond}`)) as
        | ReplacedProduct
        | undefined;

    const tagOfferAs = (
      offer: SearchOffers | undefined,
      cond: ReebeloConditions,
    ) =>
      offer == null
        ? undefined
        : {
            ...offer,
            tags: offer.tags
              .filter((t) => !t.startsWith('Condition_'))
              .concat(`Condition_${cond}`),
          };

    const isSmartphones = group.find((p) =>
      p.tags.includes(`Category_Smartphones`),
    );

    // Brand New
    const bnOffer = getWithCond(RC[ReebeloConditions.BrandNew]);

    // Premium
    let prOffer = getWithCond(RC[ReebeloConditions.Premium]);

    if (bnOffer && (!prOffer || (prOffer && prOffer.price >= bnOffer.price))) {
      prOffer = {
        replacedFromCondition: RC[ReebeloConditions.BrandNew],
        ...bnOffer,
      };
    }

    // Pristine
    let anOffer = getWithCond(RC[ReebeloConditions.Pristine]);

    if (prOffer && (!anOffer || (anOffer && anOffer.price >= prOffer.price))) {
      anOffer = {
        replacedFromCondition: RC[ReebeloConditions.Premium],
        ...prOffer,
      };
    }

    // Excellent
    let exOffer = getWithCond(RC[ReebeloConditions.Excellent]);

    if (anOffer && (!exOffer || (exOffer && exOffer.price >= anOffer.price))) {
      exOffer = {
        replacedFromCondition: RC[ReebeloConditions.Pristine],
        ...anOffer,
      };
    }

    // Very Good
    let vgOffer =
      getWithCond(RC[ReebeloConditions.VeryGood]) ||
      getWithCond(RC[ReebeloConditions.Good]);

    if (exOffer && (!vgOffer || (vgOffer && vgOffer.price >= exOffer.price))) {
      vgOffer = {
        replacedFromCondition: RC[ReebeloConditions.Excellent],
        ...exOffer,
      };
    }

    // Acceptable
    let goOffer = getWithCond(RC[ReebeloConditions.Acceptable]);

    if (vgOffer && (!goOffer || (goOffer && goOffer.price >= vgOffer.price))) {
      goOffer = {
        replacedFromCondition: RC[ReebeloConditions.Good],
        ...vgOffer,
      };
    }

    return [
      isNaStore
        ? undefined
        : tagOfferAs(bnOffer, RC[ReebeloConditions.BrandNew]),
      tagOfferAs(prOffer, RC[ReebeloConditions.Premium]),
      tagOfferAs(anOffer, RC[ReebeloConditions.Pristine]),
      tagOfferAs(exOffer, RC[ReebeloConditions.Excellent]),
      isNaStore && !isSmartphones
        ? undefined
        : tagOfferAs(vgOffer, RC[ReebeloConditions.Good]),
      tagOfferAs(goOffer, RC[ReebeloConditions.Acceptable]),
    ].filter(Boolean) as ReplacedProduct[];
  });
};

export const selectWarranty = (price: number, itemCategory = 'default') => {
  let warranties = warrantyPricing[itemCategory];

  if (warranties == null) warranties = warrantyPricing.default;
  // for cases where `warrantyPricing.default` is undefined
  if (warranties == null) return null;

  return warranties.find((w) => w.from <= price && price <= w.to);
};

export const selectionToString = (
  selection: SelectionT,
  category: string,
): string => {
  let variants: VariantNames[] = VARIANTS;

  if (category === 'Smartwatches') variants = SMARTWATCHVARIANTS;

  return variants
    .map((v) => selection[v])
    .filter((str): str is string => str != null)
    .map((str) => str.toLocaleLowerCase().replace(/ +/g, '-'))
    .join('-');
};

type OfferTreeT = Record<string, ReplacedProduct>;

export class OfferTree {
  tree: OfferTreeT;

  orderedPath: string[];

  private extraParams: Record<string, string>;

  constructor(
    offers: ReplacedProduct[],
    private category: string,
    extraParams: { handle?: string } = {},
  ) {
    this.extraParams = extraParams;
    this.category = category;
    const pathToOffer: OfferTreeT = {};

    offers.forEach((offer) => {
      const tags = extractTags(offer.tags);
      const path = this.toPath({
        ...tags,
        serviceProvider: offer.serviceProvider,
      });

      pathToOffer[path] = offer;
    });
    this.tree = pathToOffer;
    this.orderedPath = Object.keys(pathToOffer).sort(
      (pathA, pathB) => pathToOffer[pathA].price - pathToOffer[pathB].price,
    );
  }

  get(selection: Partial<VariantObj>) {
    const path = this.toPath(selection);
    const directGet = this.tree[path];

    if (directGet != null) return directGet;

    // If the path is 64GB.Blue.undefined.undefined.undefined.Good, we generate the follwing path:
    // [64GB.Blue.undefined.undefined.undefined, 64GB.Blue.undefined, 64GB.Blue, 64GB, '']
    const variants =
      this.category === 'Smartwatches' ? SMARTWATCHVARIANTS : VARIANTS;
    const candidatePaths = path
      .split(',')
      .map((_, index, arr) =>
        arr.slice(0, variants.length - index - 1).join(','),
      );

    const electedPath = candidatePaths.find((c) =>
      this.orderedPath.find((p) => p.startsWith(c)),
    ) as string; // can't be undefined since there are offers
    const potentialPaths = this.orderedPath.filter((p) =>
      p.startsWith(electedPath),
    );
    const sortedPath = sortBy(potentialPaths, (p) =>
      // path with the most common value with the selection will be selected
      p
        .split(',')
        .map((k, i) => Number(k === path[i]))
        .reduce((acc, val) => acc + val),
    );

    return this.tree[sortedPath[0]];
  }

  getFrom(
    nextSelection: Partial<VariantObj>,
    currentSelection: Partial<VariantObj>,
    index?: number,
  ):
    | null
    | (SelectionT & { replacedFromCondition?: string; suggested?: boolean }) {
    const reg = /(,1?undefined)+$/g; // trim `undefined` at the end
    const nextPath = this.toPath(nextSelection).replace(reg, '');

    const isOutOfStock =
      this.orderedPath.find((o) =>
        o
          .replace(/Bluetooth \+ LTE/, 'LTE + Bluetooth')
          .startsWith(nextPath.replace(/Bluetooth \+ LTE/, 'LTE + Bluetooth')),
      ) == null;

    // If nextPath is set to "Stainless Steel.40mm.Gold.Bluetooth," and the orderedPath contains "Stainless Steel.40mm.Gold.Bluetooth + LTE.Leather.Leather Strap.Pink.1Acceptable.4GB," the current implementation returns false, which is incorrect. Therefore, modifing the string from "Bluetooth + LTE" to "LTE + Bluetooth" to ensure the correct result.

    const replacedSelection = { ...currentSelection, ...nextSelection };

    const replacedPath = this.toPath(replacedSelection).replace(reg, '');
    const cheapestMatchedPath = this.orderedPath.find((o) =>
      o.startsWith(replacedPath),
    );

    if (isOutOfStock) {
      const target = nextPath.split(',');
      let hasTargetPath;

      if (index) {
        hasTargetPath = this.orderedPath.find((path) => {
          const p = path.split(',');

          return p[index] === target[target.length - 1];
        });
      } else {
        hasTargetPath = this.orderedPath.find(
          (o) =>
            o.includes(`,${target[target.length - 1]},`) ||
            o.startsWith(`${target[target.length - 1]},`) ||
            o.endsWith(`,${target[target.length - 1]}`),
        );
      }

      if (hasTargetPath == null) return null;

      if (hasTargetPath) {
        const offer = _get(this.tree, hasTargetPath.replace(reg, ''));

        return {
          ...this.extraParams,
          ...tagToSelection(offer.tags),
          serviceProvider: offer.serviceProvider,
          mvno: nextSelection.mvno,
          replacedFromCondition: offer.replacedFromCondition,
          suggested: true,
        };
      }
    }

    const offer =
      cheapestMatchedPath == null
        ? this.get(replacedSelection) // path is has been found
        : _get(this.tree, cheapestMatchedPath);

    if (offer == null) return null;

    return {
      ...this.extraParams,
      ...tagToSelection(offer.tags),
      serviceProvider: offer.serviceProvider,
      mvno: nextSelection.mvno,
      replacedFromCondition: offer.replacedFromCondition,
      suggested: false,
    };
  }

  /**
   * Convert a selection into JSON path like `64GB.Blue.undefined.undefined.undefined.1Good`
   */
  private toPath = (selection: Partial<VariantObj>) => {
    // condition is formated tobe sortable so in case of same price, we fallback on the best condition
    const SORTABLE_COND: Record<string, number> = {
      undefined: 1,
      [ReebeloConditions.Acceptable]: 1,
      [ReebeloConditions.Good]: 2,
      [ReebeloConditions.VeryGood]: 2,
      [ReebeloConditions.Excellent]: 3,
      [ReebeloConditions.Pristine]: 4,
      [ReebeloConditions.BrandNew]: 5,
    };
    const origCondition = selection.condition?.replace('Very Good', 'Good');
    const condition = `${
      SORTABLE_COND[origCondition || 'undefined']
    }${origCondition}`;
    const formatedSelection = { ...selection, condition };

    if (this.category === 'Smartwatches') {
      return SMARTWATCHVARIANTS.map(
        (v) => formatedSelection[v] || 'undefined',
      ).join(',');
    }

    return VARIANTS.map((v) => formatedSelection[v] || 'undefined').join(',');
  };
}

type items = {
  handle: string;
  links?: LeftMenu[] | undefined;
};

export const filterConvertCollectionHandlesToCollectionsData = (
  handles: Array<CollectionKey>,
  summaries: CollectionSummaries,
) =>
  handles
    .filter((handle) => summaries[handle] != null)
    .map((handle) => ({
      handle,
      summary: summaries[handle],
    }))
    .filter((c) => c.summary?.imageUrl);

export const findCollectionInMenu = (handle: string, templateMenu: items[]) => {
  let data: any;

  templateMenu.forEach((items) => {
    const length = items?.links?.length || 0;

    for (let i = 0; i < length; i += 1) {
      const link = items?.links?.[i];

      if (link && link.handle === handle) {
        data = items;
        break;
      }
      const sublinks = link?.links;

      if (sublinks) {
        const sublinkChild = sublinks.find(
          (sublink) => sublink.handle === handle,
        );

        if (sublinkChild) {
          data = link;
          break;
        }
      }
    }
  });

  return data?.handle;
};

export type PopularCollections = {
  title: string;
  collectionHandle: string;
  dealTitle: string;
  collections: Array<CollectionSummaryData>;
};

export const getPopularCollectionFromConfig = (
  popularCollectionsConfig: ConfigT['home_collections_slider'],
  summaries: CollectionSummaries,
) =>
  popularCollectionsConfig
    .map((popularCollection) => {
      const filteredCollections =
        filterConvertCollectionHandlesToCollectionsData(
          popularCollection.collections,
          summaries,
        );

      return {
        ...popularCollection,
        collections: filteredCollections,
        title: popularCollection.title,
      };
    })
    .filter(
      (popularCollection) => popularCollection.collections.length > 0,
    ) as Array<PopularCollections>;
